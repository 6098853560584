/* eslint-disable prettier/prettier */
import { FixedNumber } from "@ethersproject/bignumber";
import { walletStore } from "@/stores/wallet-store";
import { loadingController } from "@/components/global-loading/global-loading-controller";
import { blockchainHandler } from "@/blockchain";
import Web3 from "web3";

//TODO: Change to mainnet
const web3 = blockchainHandler.getWeb3(process.env.VUE_APP_CHAIN_ID)!;

async function sendRequest(fx, from) {
  return await new Promise((resolve, reject) => {
    fx.send({ from })
      .on("receipt", (result) => resolve(result))
      .on("error", (error) => reject(error));
  });
}

export class MysteryBoxHandler {
  mysteryBoxContract: any;
  LPTokenContract: any;
  lockedAmount?: FixedNumber;
  mysteryNftContract?: any;

  usedTicket?: FixedNumber;
  summonedIds?: Array<string>;
  isRares?: Array<boolean>;

  constructor() {
    this.LPTokenContract = new web3.eth.Contract(
      require("./erc20.abi.json"),
      process.env.VUE_APP_TOKEN_ADDRESS
    );
    this.mysteryNftContract = new web3.eth.Contract(
      require("./mystery-box-nft.abi.json"),
      process.env.VUE_APP_MYSTERY_NFT_ADDRESS
    );
    this.mysteryBoxContract = new web3.eth.Contract(
      require("./mystery.abi.json"),
      process.env.VUE_APP_MYSTERY_BOX_ADDRESS
    );
  }

  injectMetamask(web3: Web3) {
    if (web3) {
      this.mysteryBoxContract = new web3.eth.Contract(
        require("./mystery.abi.json"),
        process.env.VUE_APP_MYSTERY_BOX_ADDRESS
      );
      this.mysteryNftContract = new web3.eth.Contract(
        require("./mystery-box-nft.abi.json"),
        process.env.VUE_APP_MYSTERY_NFT_ADDRESS
      );
      this.LPTokenContract = new web3.eth.Contract(
        require("./erc20.abi.json"),
        process.env.VUE_APP_TOKEN_ADDRESS
      );
    }
  }

  async approved(account) {
    try {
      const allowance = await this.LPTokenContract.methods
        .allowance(account, process.env.VUE_APP_MYSTERY_BOX_ADDRESS)
        .call();
      return !!+web3.utils.fromWei(allowance);
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  async approve(account) {
    const f = this.LPTokenContract.methods.approve(
      process.env.VUE_APP_MYSTERY_BOX_ADDRESS,
      web3.utils.toWei(`${2 ** 64 - 1}`)
    );
    await sendRequest(f, account);
  }

  async getTotalUsedBox() {
    const usedBox = await this.mysteryBoxContract.methods.usedBox().call();
    return usedBox;
  }

  async getUserBoxIds() {
    const userBoxIds = await this.mysteryNftContract.methods.getUserIds(walletStore.account).call();
    return userBoxIds;
  }

  async spin(boxId) {
    const f = this.mysteryBoxContract.methods.spin(boxId);
    const res = await sendRequest(f, walletStore.account);
    const summonedEvent = (res as any).events["Summoned"];
    this.summonedIds = summonedEvent.returnValues.summonedIds as Array<string>;
    this.isRares = summonedEvent.returnValues.isRares as Array<boolean>;
  }

  // async transferBox(account, toAddress, nftId) {
  //   const f = this.mysteryNftContract.methods.transferFrom(walletStore.account, toAddress, nftId);
  //   await sendRequest(f, account);
  // }

  async getUserLPBalance(account) {
    const allowance = await this.LPTokenContract.methods.balanceOf(account).call();
    return FixedNumber.from(`${web3.utils.fromWei(allowance)}`);
  }
}
