import { snackController } from "@/components/snack-bar/snack-bar-controller";
import { walletStore } from "@/stores/wallet-store";
import { FixedNumber } from "@ethersproject/bignumber";
import { action, computed, IReactionDisposer, observable, reaction, runInAction, when } from "mobx";
import { asyncAction, task } from "mobx-utils";
import { Subject, timer } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NftModel } from "@/models/nft-model";
import { appProvider } from "@/app-providers";
import { localdata } from "@/helpers/local-data";
import { MysteryBoxHandler } from "@/helpers/mystery-box-handler";
import { confirmDialogController } from "@/components/confirm-dialog/confirm-dialog-controller";
import { shuffle, sortBy } from "lodash-es";

export class MysteryBoxViewModel {
  _disposers: IReactionDisposer[] = [];
  private _unsubcrible = new Subject();

  @observable opening = false;
  @observable spinSuccess = false;
  @observable isFirstLoad = false;
  @observable tokenPrice = FixedNumber.from("0");

  @observable approved = false;
  @observable approving = false;
  @observable totalUsedBox = 0;
  @observable userBoxNo = 0;
  @observable userBoxIds: any[] = [];

  @observable userLPBalance = FixedNumber.from("0");

  mysteryBoxHandler?: MysteryBoxHandler;

  @observable summonedNFTs: Array<NftModel> = [];
  @observable dataLoading = false;

  itemPerPage = 8;
  @observable currentPage = 1;
  confirmDialog = confirmDialogController;

  @observable currentBoxId = "";
  @observable isShowOpenBoxDialog = false;
  @observable isShowTransferBoxDialog = false;
  @observable isShowSummonedDialog = false;

  constructor() {
    this.loadData();
    this._disposers = [
      reaction(
        () => walletStore.account,
        () => {
          if (walletStore.isChainIdValid) this.mysteryBoxHandler?.injectMetamask(walletStore.web3!);
        }
      ),
    ];
  }

  destroy() {
    this._unsubcrible.next();
    this._unsubcrible.complete();
    this._disposers.forEach((d) => d());
  }

  @action.bound loadData() {
    const mysteryBoxHandler = new MysteryBoxHandler();
    this.mysteryBoxHandler = mysteryBoxHandler;
    this.dataLoading = true;
    this.fetchPoolInfo();

    this._disposers.push(
      when(
        () => walletStore.connected,
        async () => {
          if (walletStore.chainId == process.env.VUE_APP_CHAIN_ID) {
            mysteryBoxHandler.injectMetamask(walletStore.web3!);
            mysteryBoxHandler
              .approved(walletStore.account)
              .then((approved) => runInAction(() => (this.approved = approved)));

            timer(0, 10000)
              .pipe(takeUntil(this._unsubcrible))
              .subscribe(async () => {
                this.fetchPoolInfo();
              });

            this.getUserBoxIds();
          }
        }
      )
    );
    if (!walletStore.connected || !walletStore.isChainIdValid) this.dataLoading = false;
  }

  @asyncAction *fetchPoolInfo() {
    const totalUsedBox = yield this.mysteryBoxHandler!.getTotalUsedBox();
    this.totalUsedBox = +totalUsedBox;
    if (!this.isFirstLoad) this.isFirstLoad = true;
  }

  @asyncAction *getUserBoxIds() {
    if (walletStore.account && walletStore.isChainIdValid) {
      const userBoxIds = yield this.mysteryBoxHandler!.getUserBoxIds();
      const sortedIds = sortBy(userBoxIds.map((id) => +id));
      this.userBoxIds = sortedIds.map((id) => id + "");
      this.userBoxNo = userBoxIds.length;
      this.dataLoading = false;
    }
  }

  @asyncAction *approve() {
    this.approving = true;
    try {
      yield this.mysteryBoxHandler?.approve(walletStore.account);
      this.approved = true;
    } catch (error) {
      snackController.error(error.message);
    }
    this.approving = false;
  }

  @asyncAction *spin() {
    this.spinSuccess = false;
    if (this.userBoxNo === 0) {
      return {
        result: false,
        message: "You do not have any mystery box!",
      };
    }
    if (!this.currentBoxId) {
      snackController.error("Error occured when open box!");
      return;
    }

    this.opening = true;
    let nftIds: any = [];
    this.summonedNFTs = [];
    try {
      yield this.mysteryBoxHandler?.spin(this.currentBoxId);
      nftIds = this.mysteryBoxHandler?.summonedIds;
      this.spinSuccess = true;
      this.getUserBoxIds();

      return {
        result: true,
        message: `You have opened 5 monsters successfully!`,
      };
    } catch (error) {
      console.error(error);
      return {
        result: false,
        message: error.message.includes("Zero") ? "You do not have any mystery box!" : error.message,
      };
    } finally {
      if (this.spinSuccess && nftIds.length) {
        let nfts = yield appProvider.api.nft.find<NftModel>({
          // eslint-disable-next-line @typescript-eslint/camelcase
          ID_in: nftIds,
        });
        nfts = shuffle(nfts);

        this.summonedNFTs = [...nfts];
        const newMonsters = localdata.getNewMonsters();
        localdata.setNewMonsters([...nfts, ...newMonsters]);
        this.cancelOpenBoxDialog();
        this.setShowSummonedDialog(true);
      }
      this.opening = false;
    }
  }

  // transferBox(toAddress) {
  //   this.confirmDialog.confirm({
  //     content: `Your asset will be transferred to address <span class="accent--text">${toAddress}</span>. As a result, it will no longer be in your wallet nor will show up in your mystery box list.`,
  //     title: "Confirm transfer",
  //     doneText: "Confirm",
  //     doneCallback: async () => {
  //       try {
  //         if (!walletStore.account || !toAddress || !this.currentBoxId) {
  //           snackController.error("Error occured when making transfer NFT!");
  //           return;
  //         }
  //         await this.mysteryBoxHandler!.transferBox(walletStore.account, toAddress, this.currentBoxId);
  //         await this.fetchPoolInfo();
  //         this.cancelTransferBoxDialog();
  //         snackController.success(
  //           `Transfer mystery box #${this.currentBoxId} to wallet ${toAddress} successfully!`
  //         );
  //       } catch (error) {
  //         snackController.error(error);
  //         console.error(error);
  //       } finally {
  //         //
  //       }
  //     },
  //   });
  // }

  @action.bound changePage(page) {
    this.currentPage = page;
  }

  @action.bound setShowSummonedDialog(val) {
    this.isShowSummonedDialog = val;
  }

  @action.bound openBoxDialog(boxId) {
    this.currentBoxId = boxId;
    this.isShowOpenBoxDialog = true;
  }
  @action.bound cancelOpenBoxDialog() {
    this.currentBoxId = "";
    this.isShowOpenBoxDialog = false;
  }

  // @action.bound transferBoxDialog(boxId) {
  //   this.currentBoxId = boxId;
  //   this.isShowTransferBoxDialog = true;
  // }
  // @action.bound cancelTransferBoxDialog() {
  //   this.currentBoxId = "";
  //   this.isShowTransferBoxDialog = false;
  // }

  @computed get totalOpenedMonster() {
    return this.totalUsedBox * 5;
  }

  @computed get totalPage() {
    let totalPage = 0;
    if (this.userBoxNo % this.itemPerPage! == 0) totalPage = this.userBoxNo / this.itemPerPage!;
    else totalPage = Math.floor(this.userBoxNo / this.itemPerPage!) + 1;

    return totalPage;
  }

  @computed get slicedBoxes() {
    const slicedBoxes = this.userBoxIds.slice(
      (this.currentPage - 1) * this.itemPerPage,
      this.currentPage * this.itemPerPage
    );

    return slicedBoxes;
  }
}
