
































































































































































import { Observer } from "mobx-vue";
import { Component, Vue, Provide } from "vue-property-decorator";
import { MysteryBoxViewModel } from "@/modules/mystery-box/viewmodels/mystery-box-viewmodel";
import { walletStore } from "@/stores/wallet-store";

@Observer
@Component({
  components: {
    AllCharacterTab: () => import("@/modules/my-character/components/all-characer-tab.vue"),
    MonsterDetail: () => import("@/modules/egg-hatch/components/monster-detail.vue"),
    CharacterRow: () => import("@/components/character/character-row.vue"),
    MysteryBoxCard: () => import("@/modules/mystery-box/components/mystery-box-card.vue"),
    OpenBoxDialog: () => import("@/modules/mystery-box/dialogs/open-box-dialog.vue"),
    TransferMysteryBox: () => import("@/modules/mystery-box/dialogs/transfer-box-dialog.vue"),
    SummonedBoxDialog: () => import("@/modules/mystery-box/dialogs/summoned-box-dialog.vue"),
  },
})
export default class MysteryBox extends Vue {
  @Provide() vm = new MysteryBoxViewModel();
  walletStore = walletStore;
  opened = false;
  animating = false;

  destroyed() {
    this.vm.destroy();
  }

  // async sleep(ms) {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // }

  // async spin() {
  //   if (this.animating) return;

  //   this.opened = false;
  //   const spinResult = (await this.vm.spin()) as any;
  //   if (spinResult?.result) {
  //     this.animating = true;
  //     await this.sleep(2500);
  //     this.animating = false;

  //     this.opened = true;
  //     snackController.success(spinResult?.message);
  //   } else {
  //     snackController.error(spinResult?.message);
  //   }
  // }
}
