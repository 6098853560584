import { render, staticRenderFns } from "./mystery-box.vue?vue&type=template&id=9d5c8678&scoped=true&"
import script from "./mystery-box.vue?vue&type=script&lang=ts&"
export * from "./mystery-box.vue?vue&type=script&lang=ts&"
import style0 from "./mystery-box.vue?vue&type=style&index=0&id=9d5c8678&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d5c8678",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBtn,VCard,VCol,VDivider,VPagination,VRow,VSkeletonLoader})
